<template>
    <div class="bg-gray-100">
        <div class="flex justify-between align-items-center p-2">
            <button @click="clearDataField('all')" class="bg-white text-xs rounded border shadow-sm p-1">{{ $t('clear') }}</button>
            <small class="text-xs">{{ $t('filter') }}</small>
            <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{ $t('done') }}</button>
        </div>
        <div class="flex flex-col">
            <div>
                <!-- <div class="bg-white border-t border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="form-radio cursor-pointer" v-model="filter" value="date"/>
                        <span class="ml-2 cursor-pointer">Date</span>
                    </label>
                </div> -->
                <div class="bg-white border-b p-1"> <!--v-if="!product"-->
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="cursor-pointer" v-model="filter" value="amount"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('amount') }}</span>
                    </label>
                </div>
                <div v-if="filter.includes('amount')" class="p-1">
                    <div class="form-group">
                        <select v-model="amountObject.sort" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled value="">---</option>
                            <option value='asc'>{{ $t('ascending') }}</option>
                            <option value='desc'>{{ $t('descending') }}</option>
                        </select>
                    </div>
                </div>
                <div class="bg-white border-b p-1"> <!--v-if="!product"-->
                    <label class="flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="cursor-pointer" v-model="filter" value="margin"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('margin') }}</span>
                    </label>
                </div>
                <div v-if="filter.includes('margin')" class="p-1">
                    <div class="form-group">
                        <select v-model="marginObject.range" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option value="" selected disabled>---</option>
                            <option value='equal'>{{ $t('isEqualTo') }}</option>
                            <option value='greater'>{{ $t('isGreaterThan') }}</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                            <option value='less'>{{ $t('isLessThan') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="flex justify-between" v-if="marginObject.range === 'between'">
                            <input type="number" min='0' v-model="marginObject.start" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                            <input type="number" min='0' v-model="marginObject.end" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                        <div v-else>
                            <input type="number" min='0' v-model="marginObject.value" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                        </div>
                    </div>
                </div>
                <div class="bg-white border-b p-1">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="form-radio cursor-pointer" v-model="filter" value="sales"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('salesPrice') }}</span>
                    </label>
                </div>
                <div v-if="filter.includes('sales')" class="p-1">
                    <div class="form-group">
                        <select v-model="salesObject.range" style="width:100% !important" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option value="" selected disabled>---</option>
                            <option value='equal'>{{ $t('isEqualTo') }}</option>
                            <option value='greater'>{{ $t('isGreaterThan') }}</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                            <option value='less'>{{ $t('isLessThan') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="flex justify-between" v-if="salesObject.range === 'between'">
                            <input type="number" min="0" v-model="salesObject.start" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                            <input type="number" min="0" v-model="salesObject.end" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                        <div v-else>
                            <input type="number" min="0" v-model="salesObject.value" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                        </div>
                    </div>
                </div>

                <div class="bg-white border-b p-1">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="form-radio cursor-pointer" v-model="filter" value="cost"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('costPrice') }}</span>
                    </label>
                </div>

                <div v-if="filter.includes('cost')" class="p-1">
                    <div class="form-group">
                        <select v-model="costObject.range" style="width:100% !important" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option value="" selected disabled>---</option>
                            <option value='equal'>{{ $t('isEqualTo') }}</option>
                            <option value='greater'>{{ $t('isGreaterThan') }}</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                            <option value='less'>{{ $t('isLessThan') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="flex justify-between" v-if="costObject.range === 'between'">
                            <input type="number" min="0" v-model="costObject.start" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                            <input type="number" min="0" v-model="costObject.end" class="bg-white w-1/2 rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                        <div v-else>
                            <input type="number" min="0" v-model="costObject.value" class="bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                        </div>
                    </div>
                </div>

                <div>
                    <div class="bg-white border-b p-1">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <input type="checkbox" class="form-radio cursor-pointer" v-model="filter" value="supplier"/>
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('supplier') }}</span>
                        </label>
                    </div>
                    <div v-if="filter.includes('supplier')" class="p-1">
                        <div class="form-group">
                            <input type="text" v-model="supplier.name" class="bg-white w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                        </div>
                    </div>
                </div>

                <div class="bg-white border-b p-1" v-if="product">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="checkbox" class="form-radio cursor-pointer" v-model="filter" value="status"/>
                        <span class="ml-2 cursor-pointer text-xs">{{ $t('status') }}</span>
                    </label>
                </div>
                <div v-if="filter.includes('status')" class="p-1">
                    <div class="form-group">
                        <select v-model="statusValue.value" class="bg-white w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs w-full">
                            <option selected disabled value="">---</option>
                            <option :value="true">{{ $t('discontinued') }}</option>
                            <option :value="false">{{ $t('available') }}</option>
                        </select>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../../bus'

    export default {
        name : 'FilterWebshopProduct',
        props : {
            sub : {
                type : String,
                required : true
            },
            pricelist : {
                type : Number,
                required : true
            },
            product : {
                type : Boolean,
                required : false
            }
        },
        data () {
            return {
                filter : [],
                value1 : '',
                amountObject : {
                    sort : '',
                },
                marginObject : {
                    range : '',
                    value : '',
                    start : '',
                    end : '',
                },
                salesObject : {
                    range : '',
                    value : '',
                    start : '',
                    end : ''
                },
                costObject : {
                    range : '',
                    value : '',
                    start : '',
                    end : ''
                },
                supplier : {
                    name : ''
                },
                statusValue : {
                    value : false
                }
            }
        },
        watch : {
            filter : function (old, newVal) {
                // console.log('what is file:', this.filter)
                // window.localStorage.setItem('__filrule__', JSON.stringify(this.filter))
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_FILTER_RULE : 'jitcontrol/GET_FILTER_RULE'
            })
        },
        mounted () {
            if (this.GET_FILTER_RULE.length > 0) {
                const arrayFilter = []
                this.GET_FILTER_RULE.forEach(element => {
                    arrayFilter.push(element.mode)
                });
                this.filter = arrayFilter
                const filterAmount = this.GET_FILTER_RULE.filter(el => el.mode === 'amount')
                const filterMargin = this.GET_FILTER_RULE.filter(el => el.mode === 'margin')
                const filterSales = this.GET_FILTER_RULE.filter(el => el.mode === 'sales')
                const filterCost = this.GET_FILTER_RULE.filter(el => el.mode === 'cost')
                const filterSupplier = this.GET_FILTER_RULE.filter(el => el.mode === 'supplier')

                if (filterAmount.length > 0) {
                    this.amountObject = filterAmount[0].property
                }

                if (filterMargin.length > 0) {
                    this.marginObject = filterMargin[0].property
                }

                if (filterSales.length > 0) {
                    this.salesObject = filterSales[0].property
                }

                if (filterCost.length > 0) {
                    this.costObject = filterCost[0].property
                }

                if (filterSupplier.length > 0) {
                    this.supplier = filterSupplier[0].property
                }
            } 
        },
        methods : {
            filterDataNow () {
                const payload = []

                if (this.filter.includes('amount')) {
                    if (this.amountObject && this.amountObject.sort !== '') {
                        const obj = {}
                        obj.mode = 'amount'
                        obj.property = this.amountObject
                        obj.value = this.amountObject.sort
                        payload.push(obj)
                        // this.$store.commit('jitcontrol/ADVANCE_SORT_WEBSHOP_PRODUCT', this.amountObject)
                    }
                }

                if (this.filter.includes('margin')) {
                    if (this.marginObject.range && (this.marginObject.value || this.marginObject.start || this.marginObject.end)) {
                        const obj = {}
                        obj.mode = 'margin'
                        obj.property = this.marginObject
                        obj.value = this.marginObject.value ? `[${this.marginObject.value}]` : `[${this.marginObject.start} - ${this.marginObject.end}]` 
                        payload.push(obj)
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_WEBSHOP_PRODUCT_MARGIN', this.marginObject)
                    }
                }

                if (this.filter.includes('sales')) {
                    if (this.salesObject.range && (this.salesObject.value || this.salesObject.start || this.salesObject.end)) {
                        const obj = {}
                        obj.mode = 'sales'
                        obj.property = this.salesObject
                        obj.value = this.salesObject.value ? `[${this.salesObject.value}]` : `[${this.salesObject.start} - ${this.salesObject.end}]` 
                        payload.push(obj)
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_WEBSHOP_PRODUCT_SALES', this.salesObject)
                    }
                }

                if (this.filter.includes('cost')) {
                    if (this.costObject.range && (this.costObject.value || this.costObject.start || this.costObject.end)) {
                        const obj = {}
                        obj.mode = 'cost'
                        obj.property = this.costObject
                        obj.value = this.costObject.value ? `[${this.costObject.value}]` : `[${this.costObject.start} - ${this.costObject.end}]` 
                        payload.push(obj)
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_WEBSHOP_PRODUCT_COST', this.costObject)
                    }
                }

                if (this.filter.includes('supplier')) {
                    if (this.supplier && this.supplier.name !== '') {
                        const obj = {}
                        obj.mode = 'supplier'
                        obj.property = this.supplier
                        payload.push(obj)
                    }
                }

                if (this.filter.includes('status')) {
                    const payload = {
                        id: this.sub,
                        pricelist: this.pricelist,
                        query: `?status=${this.statusValue.value}`,
                    };
                    this.$store
                        .dispatch('jitcontrol/getWebshopProductSearch', payload)
                        .then((_) => {
                            if (this.product) {
                                Bus.emit('search-result', { product: true });
                            } else {
                                Bus.emit('search-result', { product: false });
                            }
                        })
                    .catch((err) => {
                        if (err.response.status === 401) {
                        this.$store
                            .dispatch('auth/refreshToken', { refresh: this.USER_REFRESH })
                            .then((_) => {
                                this.filterDataNow();
                            })
                            .catch((_) => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null);
                                Bus.emit('sign-out');
                            });
                        } else {
                        if (this.product) {
                            Bus.emit('search-result', { product: true });
                        } else {
                            Bus.emit('search-result', { product: false });
                        }
                        }
                    });
                }

                // if (this.filter === 'supplier') {
                //     if (this.supplier && this.supplier.name !== '') {
                //         Bus.emit('search-loader', true)
                //         const payload = {
                //             id : this.sub, 
                //             pricelist : this.pricelist,
                //             query : `?supplier=${this.supplier.name}`
                //         }
                //         this.$store.dispatch('jitcontrol/getWebshopProductSearch', payload)
                //         .then(_ => {
                //             if (this.product) {
                //                 Bus.emit('search-result', { product: true })
                //             } else {
                //                 Bus.emit('search-result', { product : false })
                //             } 
                //         })
                //         .catch(err => { 
                //             if (err.response.status === 401) {
                //                 this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                //                 .then(_ =>{
                //                     this.filterDataNow()
                //                 })
                //                 .catch(_ => {
                //                     this.$store.commit('auth/CLEAR_AUTH_USER', null)
                //                     Bus.emit('sign-out')
                //                 })
                //             } else {
                //                 if (this.product) {
                //                     Bus.emit('search-result', { product: true })
                //                 } else {
                //                     Bus.emit('search-result', { product : false })
                //                 }
                //             }
                //          })
                //     }
                // }

                // if (this.filter === 'status') {
                //     Bus.emit('search-loader', true)
                //     const payload = {
                //         id : this.sub, 
                //         pricelist : this.pricelist,
                //         query : `?status=${this.statusValue.value}`
                //     }
                //     this.$store.dispatch('jitcontrol/getWebshopProductSearch', payload)
                //     .then(_ => {
                //         // this.$store.commit('jitcontrol/UPDATE_WEBSHOP_PRODUCTS', { data : res.data, search : true })
                //         if (this.product) {
                //             Bus.emit('search-result', { product: true })
                //         } else {
                //             Bus.emit('search-result', { product : false })
                //         }
                //     })
                //     .catch(err => { 
                //         if (err.response.status === 401) {
                //             this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                //             .then(_ =>{
                //                 this.filterDataNow()
                //             })
                //             .catch(_ => {
                //                 this.$store.commit('auth/CLEAR_AUTH_USER', null)
                //                 Bus.emit('sign-out')
                //             })
                //         } else {
                //             if (this.product) {
                //                 Bus.emit('search-result', { product: true })
                //             } else {
                //                 Bus.emit('search-result', { product : false })
                //             }
                //         }
                //     })
                // }
                
                // this.clearDataField()
                this.$store.commit('jitcontrol/UPDATE_FILTER', payload)
                this.$emit('multipleFilter', payload)
                this.$emit('hideAdvanceFilter')
            },
            clearDataField (reset = null) {
                this.filter = []
                Object.keys(this.amountObject).forEach(element => { this.amountObject[element] = '' })
                Object.keys(this.marginObject).forEach(element => { this.marginObject[element] = '' })
                Object.keys(this.salesObject).forEach(element => { this.salesObject[element] = '' })
                Object.keys(this.supplier).forEach(element => { this.supplier[element] = '' })
                Object.keys(this.statusValue).forEach(element => { this.statusValue[element] = false })
                if (reset !== null) {
                    // this.$store.commit('portal/FILTER_ALL')
                    if (this.product) {
                        Bus.emit('reset-products', { product: true })
                    } else {
                        Bus.emit('reset-products', { product : false })
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.el-date-editor--daterange.el-input__inner{
    width: unset !important;
}
</style>